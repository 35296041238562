import styled from 'styled-components';
import arrow from 'assets/images/arrow.svg';

export const StyleWrapper = styled.div`
  width: 100%;
  height: auto;
  min-height: 355px;
  display: flex;
  flex: 1;
  flex-direction: column;
  transition: height 280ms cubic-bezier(0.4, 0, 0.2, 1);

  ${({ theme }) => theme.media.desktop} {
    width: 30%;
    display: flex;
    flex: 1;
    flex-direction: column;
    transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
    padding: 1rem 2rem;
  }
`;
// export const StyledSongLIst = styled.div`
// width: 100%;
// height: ${({ isCurrentSong }) => (isCurrentSong ? 'auto' : '0')};
// display: flex;
// flex-direction: column;
// margin: 5px 0;
// opacity: ${({isCurrentSong}) => isCurrentSong ? '1':'0'};
// transform: translateY(${({ isCurrentSong }) => (isCurrentSong ? '-0%' : '-100%')});
// overflow: hidden;
// transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
// border: 1px solid red;
// `
export const StyledDiv = styled.div`
  position: relative;
  transition: all 280ms cubic-bezier(0.4, 0, 0.2, 1);

  .collapse-css-transition {
    transition: height 580ms cubic-bezier(0.4, 0, 0.2, 1);
  }
`;

export const H3 = styled.h3`
  width: auto;
  display: block;
  color: ${({ isBlue }) => isBlue ? ({ theme }) => theme.blue : ({ theme }) => theme.blackfont};
  font-size: ${({ theme }) => theme.font.size.xxm};
  font-weight: 600;
  line-height: 1.5;
`;


export const StyledP = styled.div`
  height: auto;
  font-size: ${({ theme }) => theme.font.size.xs};
  color: ${({ theme }) => theme.blackfont};

  p a {
    overflow: auto;
    padding: 7px 0 7px 5px;
    color: ${({ theme }) => theme.black};
  }

  li a {
    color: ${({ theme }) => theme.black};
  }

  li {
    padding: 7px 0;
    color: ${({ theme }) => theme.black};
  }

  a:hover {
    color: ${({ theme }) => theme.bluehover};
  }
`;

export const LinksHead = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 0 5px;
  margin: 5px 0;
  font-weight: 600;

  ${({ theme }) => theme.media.desktop} {
  }
`;
// export const StyledButton=styled.button`
// margin: 0 auto;
//   font-size: 1.6rem;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   max-width: 500px;
//   padding: 3rem 1rem 1rem;
// `

export const StyledButton = styled.button`
  width: 100%;
  margin: 1rem 0;
  border: 1px solid transparent;
  text-transform: uppercase;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 2px;
  box-shadow: 0 2px 4px #888;
  background-color: #fff;
  transition: background-color 300ms;

  &--active {
    background-color: #fafafa;
  }


`;
export const StyledArrow = styled(arrow)`
  width: 18px;
  height: 18px;
  margin-top: 5px;
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: ${({ isopen }) => isopen ? 'rotateZ(-90deg)' : 'rotateZ(90deg)'};

`;

export const Img = styled.img`
  width: 130px;
  height: 180px;
  padding: 10px;

  ${({ theme }) => theme.media.desktop} {
    width: 115px;
    height: 160px;
    margin-top: 0;
    margin-right: 15px;
    padding: 5px;

  }
`;
export const StyledTitle = styled.div`

  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 5px;

`;
export const StyledDes = styled.div`

  width: 100%;
  border: 1px solid ${({ theme }) => theme.border};
  border-radius: 3px;
  box-shadow: 0 2px 4px #888;
  background-color: #fff;
  padding: 0 10px;
`;
export const StyledDesWrapper = styled.div`
  width: 95%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 0 !important;

  ${({ theme }) => theme.media.tablet} {
    flex-direction: row;
    width: auto;
    max-width: 650px;
  }

  ${({ theme }) => theme.media.desktop} {
    width: auto;
    max-width: none;
    display: flex;
    padding-left: 15px;

  }

  p {
    height: 30px;
    margin-bottom: 0;

  }
`;

export const StyledTitleTextbox = styled.div`
  width: 90vw;
  height: auto;
  margin-top: ${({ one }) => one ? '5px' : '40px'};
  font-size: ${({ theme }) => theme.font.size.ss};
  color: ${({ theme }) => theme.black};
  margin-bottom: 0;
  text-align: center;

  p {
    font-size: ${({ theme }) => theme.font.size.s};
    padding-bottom: ${({ one }) => one ? '0px' : '30px'};

  }

  h3 {
    font-size: ${({ theme }) => theme.font.size.xxm};
  }

  ${({ theme }) => theme.media.desktop} {
    width: auto;
    height: auto;
    font-size: ${({ theme }) => theme.font.size.xm};
    text-align: left;

    h3 {
      font-size: ${({ theme }) => theme.font.size.xm};
    }
  }
`;
export const StyledPerformerSong = styled.div`
  width: auto;
  height: auto;
  font-size: ${({ theme }) => theme.font.size.xxs};
  color: ${({ theme }) => theme.black};
  margin-bottom: 0;
  margin-top: 5px;
  padding: 5px;
  text-align: center;

  p {
    height: auto;
    margin-bottom: 0;
  }

  ${({ theme }) => theme.media.desktop} {
    text-align: left;
  }
`;

export const StyledImgTextboxs = styled.div`
  display: flex;
  margin: 0 auto;

  ${({ theme }) => theme.media.desktop} {
    margin: 0;
  }
`;

export const StyledDesInner = styled.div`
  width: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
export const StyledInfo = styled.div`
  width: 100%;
  padding: 25px;
  font-weight: 700 !important;
  display: grid;
  place-items: center;
`;