import React, {useState} from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Collapse } from '@kunukn/react-collapse' // UMD
import '@kunukn/react-collapse/dist/react-collapse.css' // CSS

import {StyleWrapper, StyledDiv, StyledP, LinksHead, H3, StyledButton, StyledArrow, Img, StyledTitle, StyledDes, StyledDesWrapper, StyledTitleTextbox, StyledPerformerSong,
  StyledImgTextboxs, StyledDesInner, StyledInfo} from './StylesSong'



const Songs = () => {

  const [isCollapsed, setIsCollapsed] = useState(false)
  const [isSelected, setIsSelected] = useState('')

    const data = useStaticQuery(graphql`
    query{ allDatoCmsSong(sort: {fields: sort}) {
      nodes {
        id
        sort
        textbox {
          customData
          url
          filename
          alt
        }
        descritption
        performer
        title
        song
       turnoffthisclass
          textwhenoffclass
    }
  }
}
  `)

const Collapsed = (id) =>{
setIsCollapsed(!isCollapsed);
  setIsSelected(id);
}


    return (
      <StyleWrapper>
        <H3>Piosenki stanowiące załącznik do Podręcznika</H3>
        {data.allDatoCmsSong.nodes.map(Song => (

        <StyledDiv key={ Song.id}>

          <StyledButton onClick={()=> Collapsed(Song.id)} >
            <StyledTitle><div dangerouslySetInnerHTML={{ __html: Song.title }}/>
            <StyledArrow isopen={isSelected === Song.id && isCollapsed === true}/>
            </StyledTitle>
            </StyledButton>

            <Collapse className="collapse-css-transition" isOpen={isSelected === Song.id && isCollapsed === true}>
          <div>
            {Song.turnoffthisclass ? <StyledInfo><h2>{Song.textwhenoffclass}</h2></StyledInfo> :
              <>
            <StyledDes>
              <StyledDesWrapper>
          <StyledImgTextboxs>
              {Song.textbox.map(fotoNews => (
                <a href={fotoNews.customData.ownUrl} target="_blank" rel="noopener noreferrer" key={fotoNews.filename}>
                  <Img src={fotoNews.customData.ownUrl} alt={fotoNews.filename} />
                  </a>
                ))}
          </StyledImgTextboxs>

                <StyledDesInner>
                  <StyledTitleTextbox one={Song.performer} dangerouslySetInnerHTML={{ __html: Song.descritption }} />
                    {Song.performer ? (<StyledPerformerSong per dangerouslySetInnerHTML={{ __html: Song.performer }} />) : ''}
                  </StyledDesInner>

                </StyledDesWrapper>
              </StyledDes>

               <LinksHead >
                  <StyledP className="fix" dangerouslySetInnerHTML={{ __html: Song.song }} />
              </LinksHead>
              </>}
              </div>
            </Collapse>

        </StyledDiv>
        ))}
      </StyleWrapper>
);
}
export default Songs;